import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`One of the "`}<a parentName="p" {...{
        "href": "https://overreacted.io/things-i-dont-know-as-of-2018/"
      }}>{`Things I Don't Know`}</a>{`" in the iOS ecosystem is Metal. As such, I've been working through `}<a parentName="p" {...{
        "href": "https://metalbyexample.com/"
      }}>{`Metal By Example`}</a>{` recently, which still stands as a great introduction to Metal. Invariably I've hit issues debugging my shader pipelines, and `}<a parentName="p" {...{
        "href": "https://app.plangrid.com/projects/712902dd-d42d-d9de-9557-86222a6ef5b2/sheets"
      }}>{`GPU Frame Capture`}</a>{` has been a really invaluable tool.`}</p>
    <p>{`One weird thing I noticed was that in the `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/MetalByExampleExamples"
      }}>{`examples`}</a>{` I was writing, the camera button in Xcode which enables GPU Frame Capture would flicker, making it really hard to click:`}</p>
    <CaptionedImage max={400} filename="gpu-frame-capture.gif" alt="GIF showing the camera button flickering" caption="" mdxType="CaptionedImage" />
    <p>{`After puzzling over this for a while, Warren Moore, who wrote the book (!) `}<a parentName="p" {...{
        "href": "https://twitter.com/warrenm/status/1188915996125356032"
      }}>{`reached out`}</a>{` on Twitter to note that this might happen because I was calling the device's `}<inlineCode parentName="p">{`makeCommandQueue`}</inlineCode>{` every frame, when really I should be creating one up front and saving it.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/noahsark769/MetalByExampleExamples/commit/6a6e7b0320bc877e58c055fd5f084fe91ff5e1ad#diff-c11c08d51dee2b1b6b212e4dc20e1d65R21"
      }}>{`It worked`}</a>{`, and now the flicker doesn't happen anymore! 🎉`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      